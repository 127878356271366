import { Field, Form, Formik } from 'formik';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import WsStaticImage from '@/components/common/WsStaticImage';
import { AppDispatch } from '@/store';
import {
  checkPincode,
  setFastChange,
  setFastUpdate,
} from '@/store/features/commonSlice';
import { addPincodeLead } from '@/store/features/crmSlice';
import { getStoreLocation } from '@/store/features/storeLocationSlice';
import { phoneRegex, postRegex } from '@/utils/commonValidations';
import Image from 'next/image';
import styles from './style.module.scss';
const WsButton = dynamic(() => import('@/components/common/WsButton'));

interface FastModalProps {
  isOpen: boolean;
  handleFastClose: any;
}
const validationSchema = yup.object().shape({
  pincode: yup
    .string()
    .trim()
    .required('Kindly provide a valid pincode')
    .matches(postRegex, 'Please enter a valid Pincode')
    .test('len', 'Pincode Must be exactly 6 characters', (val: any) => {
      return val?.length == 6;
    }),
  telephone: yup
    .string()
    .trim()
    .nullable()
    .required('Kindly provide a valid mobile number')
    .matches(phoneRegex, 'Kindly provide a valid mobile number')
    .test('len', 'Phone Number Must be exactly 10 characters', (val: any) => {
      return val ? val?.length == 10 : true;
    }),
});

const FastModal: React.FC<FastModalProps> = ({ handleFastClose, isOpen }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [pinText, setPinText] = useState<string>('');
  const [isThankOpen, setThankOpen] = useState(false);
  const [pinErrors, setPinErrors] = useState<string>('');
  const [initialValues, setInit] = useState({ pincode: '', telephone: '' });
  useEffect(() => {
    const exp = localStorage.getItem('expressSession');
    if (exp) {
      const espJson = JSON.parse(exp);
      setInit({ pincode: espJson.pincode, telephone: espJson.phone });
    } else setInit({ pincode: '', telephone: '' });
  }, [isOpen]);
  useEffect(() => {
    if (isThankOpen) setPinData();
  }, [isThankOpen]);
  const setPinData = async () => {
    if (pinText?.length === 6)
      await dispatch(getStoreLocation({ pin: pinText })).then(() => {
        setTimeout(() => {
          setThankOpen(false);
          handleFastClose();
        }, 2500);
      });
  };
  const onSubmit = async (
    formData: any,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setLoading(true);
    try {
      const data = formData;
      setSubmitting(false);
      const checkPin = await dispatch(
        checkPincode({ pincode: data.pincode })
      ).then((res: any) => {
        if (res?.payload?.data?.data) return res?.payload?.data?.data;
        else setPinErrors('Delivery option not available on requested area.');
      });
      if (checkPin?.pincodeData) {
        setPinText(data.pincode);
        const ep = JSON.stringify({
          isOn: true,
          phone: data.telephone,
          pincode: data.pincode,
          city: checkPin?.pincodeData?.city,
          plant: checkPin?.pincodeData?.sap_store_code,
        });
        localStorage.setItem('expressSession', ep);
        dispatch(setFastChange());
        setTimeout(() => {
          dispatch(setFastUpdate());
        }, 2000);
        setThankOpen(true);
        const post: any = {
          sourceId: 95,
          pincode: data.pincode,
          mobile: data.telephone,
          city: checkPin?.pincodeData?.city,
        };
        if (localStorage.customerDetails) {
          const customerDetails = JSON.parse(localStorage.customerDetails);
          post.customer_id = customerDetails.customer_id;
          post.email = customerDetails.email;
          post.name = customerDetails.firstname;
          if (customerDetails.lastname)
            post.name += ' ' + customerDetails.lastname;
        }
        dispatch(addPincodeLead(post));
      }
    } catch (error) {
      console.error('Account edit error', error);
    }
    setLoading(false);
  };
  const handleClose = () => {
    handleFastClose();
  };
  return (
    <div
      className={`${styles['fastfilter-box']} ${styles[isOpen ? 'open' : 'close']}  ${isThankOpen ? styles['thank'] : ''}`}
    >
      <p className={styles['close-filter-fast']} onClick={handleClose}>
        {' '}
        <WsStaticImage
          src={'/images/category/modal-close-img.svg'}
          alt="media"
          width={16}
          height={16}
        />
      </p>
      <div className={styles['flex']}>
        <p className={styles['delivery-icon']}>
          <Image
            src="/images/category/delivery-icon.svg"
            alt="media"
            width={60}
            height={65}
          />
        </p>
        <p className={styles['delivery-heading']}>
          Express Free Delivery
          <span className={styles['delivery-subhead']}>
            Select from our fast-shipping products and have them at your
            doorstep.
          </span>
        </p>
      </div>
      {!isThankOpen ? (
        <Formik
          onSubmit={onSubmit}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ errors, values, touched, handleChange }) => (
            <Form className={styles.field__form}>
              <div className={styles.formgroup}>
                <label
                  htmlFor="pincode"
                  className={values.pincode != '' ? styles.field__label : ''}
                >
                  Pincode
                </label>
                <Field
                  name="pincode"
                  id="pincode"
                  type="number"
                  maxLength={6}
                  placeholder="Enter Pincode"
                  className={
                    errors.pincode && touched.pincode ? styles.texterror : ''
                  }
                  onChange={(e: any) => {
                    setPinErrors('');
                    const text = e.target.value;
                    const numericValue = text.replace(/[^0-9]/g, '');
                    numericValue.length < 7 &&
                      handleChange('pincode')(numericValue);
                  }}
                />
                {errors.pincode && touched.pincode && <p>{errors.pincode}</p>}
                {pinErrors && <p>{pinErrors}</p>}
              </div>
              {!initialValues.telephone && (
                <div className={styles.formgroup}>
                  <label
                    htmlFor="telephone"
                    className={
                      values.telephone != '' ? styles.field__label : ''
                    }
                  >
                    Phone Number
                  </label>
                  <Field
                    type="tel"
                    id="telephone"
                    key="telephone"
                    name="telephone"
                    placeholder="Phone Number"
                    className={
                      errors.telephone && touched.telephone
                        ? styles.texterror
                        : ''
                    }
                    maxLength={10}
                    onChange={(e: any) => {
                      const text = e.target.value;
                      const numericValue = text.replace(/[^0-9]/g, '');
                      numericValue.length < 11 &&
                        handleChange('telephone')(numericValue);
                    }}
                  />
                  {errors.telephone && touched.telephone && (
                    <p>{errors.telephone}</p>
                  )}
                </div>
              )}
              <div className={styles.btngroup}>
                <WsButton
                  type="submit"
                  disabled={loading}
                  className={styles.updatebutton}
                >
                  SHOW PRODUCTS
                </WsButton>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className={styles.field__form}>
          <div className={styles.thankyou}>
            Thank you for submitting your details, based on your locations we've
            found products available at nearby your location,
          </div>
        </div>
      )}
    </div>
  );
};

export default FastModal;
